body,
.Main {
	width: 100vw;
	height: 100vh;
}

.Main-logo {
	height: 40vmin;
	pointer-events: none;
}

.Main-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.flex-start {
	display: flex;
	align-items: flex-start;
}

.snap-card-start {
	scroll-snap-align: 'start';
}

 
.middle-box{
	top: 110px; // header height + 5px
	position: relative;
	overflow-y: scroll;
	height: calc(100vh - 110px - 56px); // height is maximum height minus header minus bottom bar
}

.pointer{
	cursor: pointer;
}
